import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslationDto } from 'src/app/shared/dto/advertisements/TranslationDto.types';
import { GenericService } from 'src/app/shared/GenericService';
import { environment } from 'src/environments/environment';

@Injectable()
export class TranslationService extends GenericService<TranslationDto, number> {

    constructor(protected http: HttpClient) {
        super(http);
        this.url = environment.urlPrefix + '/arzakena-be-advertisement/advertisement/translation';
    }

    create(trans: TranslationDto): Observable<TranslationDto> {
        return this.http.post<TranslationDto>(`${this.url}/admin`, trans);
    }

    findByAdvertisementId(advId: number): Observable<TranslationDto[]> {
        return this.http.get<TranslationDto[]>(`${this.url}/findByAdvertisementId/${advId}`);
    }
}
