import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TimestampFormatPipe } from 'src/app/shared/pipes/timestampFormatPipe';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { Pageable } from 'src/app/shared/dto/Pageable.types';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { SmartContractAddressMappingService } from 'src/app/services/core-catalog/smart-contract-address-mapping/smartContractAssetMapping.service';
import { blockchainTypes } from 'src/app/shared/dto/commons/BlockchainType.types';

@Component({
  selector: 'app-smart-contract-address-mapping',
  templateUrl: './smart-contract-address-mapping.component.html',
  styleUrls: ['./smart-contract-address-mapping.component.scss'],
  providers: [
    SmartContractAddressMappingService
  ]
})
export class SmartContractAddressMappingComponent extends GenericListComponent implements OnInit, OnDestroy {

  readonly blockchainTypes = blockchainTypes;

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private smartContractAddressMappingService: SmartContractAddressMappingService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
    this.displayedColumns = ['id', 'smartContractAddress', 'assetDefinition.symbol', 'builtOn', 'alias', 'detail'];
    this.parameters = {
      dataSource: [],
      showList: false,
      filter: undefined,
      sort: undefined,
      page: 0,
      size: 10,
      builtOn: undefined,
      length: 0
    };
  }

  list(): void {
    this.smartContractAddressMappingService.query(
        { page: this.parameters.page, size: this.parameters.size, sort: this.parameters.sort } as Pageable,
        this.parameters.filter,
        this.parameters.builtOn
    ).pipe(
        takeUntil(this._unsubscribeAll),
    ).subscribe((messages) => {
      this.parameters.dataSource = messages.content;
      this.parameters.page = messages.page;
      this.parameters.length = (messages.number + 1) * messages.size +1;
      this.parameters.showList = true;
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.list();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
